<template>
<v-container>
    <div>
        <navbar title="جزئیات خرید"/>
        <div class="pt-15">
            <address-card :address= 'address' class="mb-4"/>
            <cost-card :cost = 'cost' />
        </div>
    </div>
</v-container>
</template>
<script>
import addressCard from "./components/cards/detailAddress"
import costCard from "./components/cards/detailCost"
import navbar from '@/components/navbar/appBar'
import { factorviweDetail } from './models/factors'
import { mapState } from 'vuex'
export default {
    props: ['factoryID'],
    components: {
        addressCard,
        costCard,
        navbar
    },
    created () {
        factorviweDetail(this.$props.factoryID)
    },
    computed: {
      ...mapState({
        address: state => state.factors.factor_address_info,
        cost: state => state.factors.factor_const_info
      })
    }
}
</script>